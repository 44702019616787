import { UtilsService } from './../../utils/utils.service';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { take } from 'rxjs';
import { NotificationPreferencesRequest } from 'src/app/models/notification-preferences.model';
import { BackendService } from 'src/app/services/backend.service';
import { environment } from 'src/environments/environment';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { AuthService } from 'src/app/auth/auth.service';
import { AuthResponse } from 'src/app/auth/auth.model';


type ProfileType = {
  givenName?: string,
  surname?: string,
  userPrincipalName?: string,
  id?: string
};

@Component({
  selector: 'app-notification-preferences',
  templateUrl: './notification-preferences.component.html',
  styleUrls: ['./notification-preferences.component.css']
})
export class NotificationPreferencesComponent implements OnInit {
  updateContactInfo(event: any, url: string) {
    event.preventDefault();
      window.open(url, '_blank');
  }
  //@ts-ignore
  myForm: FormGroup;
  days: { name: string, label: string, timeWindows: any[] }[] = [
      { name: 'Sunday', label: '', timeWindows: [{}] },
      { name: 'Monday', label: '', timeWindows: [{}] },
      { name: 'Tuesday', label: '', timeWindows: [{}] },
      { name: 'Wednesday', label: '', timeWindows: [{}] },
      { name: 'Thursday', label: '', timeWindows: [{}] },
      { name: 'Friday', label: '', timeWindows: [{}] },
      { name: 'Saturday', label: '', timeWindows: [{}] }
  ];
  profile!: ProfileType;
  emailInput: [] = [];
  showEmailInput: boolean = false;
  email: string = '';
  phoneNumber: string = '';
  accessToken: string | null= null;
  isLoading = true;
  copied = false;
  copiedTimes: any = null;
  tooltipVisible = false;
  employeeId: any = '';
  userData: AuthResponse | null= null;
  constructor(private utilsService: UtilsService, private authService: AuthService, private fb: FormBuilder, private http: HttpClient, private router: Router, private backendService: BackendService) {}

  async ngOnInit() {
    const urlParams = new URLSearchParams(window.location.search);
    const oboToken = urlParams.get('access_token');

    if (!oboToken) {
        if (this.authService.isAuthenticated()) {
            this.employeeId = this.authService.getIdentityClaims().employeeId;
            this.initializeForm();
            this.initialize();
            this.isLoading = false;
        }
    } else {
        if (this.authService.isAuthenticatedOBO()) {
          this.userData = this.authService.getOBOUserDataStorage();
          this.initializeForm();
          this.initialize();
          this.isLoading = false;
        } else this.router.navigate(['/error'], { queryParams: { errorCode: 500 } });
    }
  }

  initialize() {

      this.backendService.getEmailandPhonenumber(this.employeeId).subscribe(data => {
           this.email = data.email;
           this.phoneNumber = data.phoneNumber;
           console.log(`Email and phone number + ${this.email} + ${this.phoneNumber}`);
      });
  }
  openMessage() {
      const modelDiv = document.getElementById('myModal');
      if (modelDiv != null) {
          modelDiv.style.display = 'block';
      }
  }
  CloseMessage() {
      const modelDiv = document.getElementById('myModal');
      if (modelDiv != null) {
          modelDiv.style.display = 'none';
      }
  }

  private initializeForm() {
      this.myForm = this.fb.group({
          sparq: new FormControl(false),
          ivr: new FormControl(false),
          sms: new FormControl(false),
          email: new FormControl(false),
          anytime: new FormControl(false),
          ivrInput: new FormControl(""),
          smsInput: new FormControl(""),
          timeOption: new FormControl("anytime"),

          ...this.days.reduce((controls: { [key: string]: FormControl }, day, dayIndex) => {
              controls[day.name] = new FormControl(false);
              day.timeWindows.forEach((timeWindow, timeWindowIndex) => {
                  controls[day.name + '-startTime-' + timeWindowIndex] = new FormControl();
                  controls[day.name + '-endTime-' + timeWindowIndex] = new FormControl();
              });
              return controls;
          }, {})
      });


      const timeOptionControl = this.myForm.get('timeOption');
      if (timeOptionControl) {
          timeOptionControl.valueChanges.subscribe(value => {
              if (value === 'anytime') {
                  this.myForm.get('anytime')?.setValue(true);
              } else {
                  this.myForm.get('anytime')?.setValue(false);
              }
              if (value === 'weekdays') {
                ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].forEach(day => {
                    this.myForm.get(day)?.setValue(true);
                });
            }
                        if (value === 'custom') {
                this.days.forEach(day => {
                    this.myForm.get(day.name)?.setValue(false);
                });
            }
          });
      }

      console.log(this.myForm)
  }

  onSubmit() {
      if (this.myForm.value.ivr) {
          const payload = this.formatNotificationRequest(this.myForm.value, 'IVR');
          this.backendService.saveNotificationPreferences(payload).subscribe({
              next(value) {

              },
              error(err) {

              },
          });
      }

      if (this.myForm.value.sms) {
          const payload = this.formatNotificationRequest(this.myForm.value, 'SMS');
          this.backendService.saveNotificationPreferences(payload).subscribe({
              next(value) {

              },
              error(err) {

              },
          });
      }

      if (this.myForm.value.email) {
          const payload = this.formatNotificationRequest(this.myForm.value, 'EMAIL');
          this.backendService.saveNotificationPreferences(payload).subscribe({
              next(value) {

              },
              error(err) {

              },
          });
      }

  }

  copy(day: any, i: number) {
    this.copiedTimes = {
        startTime: this.myForm.value[day.name + '-startTime-' + i],
        endTime: this.myForm.value[day.name + '-endTime-' + i]
    };
    this.tooltipVisible = true;
    setTimeout(() => {
        this.tooltipVisible = false;
    }, 3000);
}

  paste(day: any, i:number) {
    if (this.copiedTimes && this.myForm.value[day.name]) {
        this.myForm.controls[day.name + '-startTime-' + i].setValue(this.copiedTimes.startTime);
        this.myForm.controls[day.name + '-endTime-' + i].setValue(this.copiedTimes.endTime);
    }
}

  addTimeWindow(day: any) {
      // Add a new time window to the specified day
      day.timeWindows.push({});
      this.myForm.addControl(day.name + '-startTime-' + (day.timeWindows.length - 1), new FormControl());
      this.myForm.addControl(day.name + '-endTime-' + (day.timeWindows.length - 1), new FormControl());
      console.log(this.myForm);
  }

  removeTimeWindow(day: any, index: number) {
      // Remove the specified time window from the specified day
      day.timeWindows.splice(index, 1);
      this.myForm.removeControl(day.name + '-startTime-' + index);
      this.myForm.removeControl(day.name + '-endTime-' + index);
      console.log(this.myForm);
  }

  formatNotificationRequest(formValue: FormGroup, channel: any) {
      let mondayStartTime;
      let mondayEndTime;
      let tuesdayStartTime;
      let tuesdayEndTime;
      let wednesdayStartTime;
      let wednesdayEndTime;
      let thursdayStartTime;
      let thursdayEndTime;
      let fridayStartTime;
      let fridayEndTime;
      let saturdayStartTime;
      let saturdayEndTime;
      let sundayStartTime;
      let sundayEndTime;


      if (this.myForm.value.Monday == true) {
          mondayStartTime = this.myForm.value['Monday-startTime-0'] ? this.myForm.value['Monday-startTime-0'] : '00:00:00.000Z';
          mondayEndTime = this.myForm.value['Monday-endTime-0'] ? this.myForm.value['Monday-endTime-0'] : '23:59:00.000Z';
      }

      if (this.myForm.value.Tuesday == true) {
          tuesdayStartTime = this.myForm.value['Tuesday-startTime-0'] ? this.myForm.value['Tuesday-startTime-0'] : '00:00:00.000Z';
          tuesdayEndTime = this.myForm.value['Tuesday-endTime-0'] ? this.myForm.value['Tuesday-endTime-0'] : '23:59:00.000Z';
      }
      if (this.myForm.value.Wednesday == true) {
          wednesdayStartTime = this.myForm.value['Wednesday-startTime-0'] ? this.myForm.value['Wednesday-startTime-0'] : '00:00:00.000Z';
          wednesdayEndTime = this.myForm.value['Wednesday-endTime-0'] ? this.myForm.value['Wednesday-endTime-0'] : '23:59:00.000Z';
      }
      if (this.myForm.value.Thursday == true) {
          thursdayStartTime = this.myForm.value['Thursday-startTime-0'] ? this.myForm.value['Thursday-startTime-0'] : '00:00:00.000Z';
          thursdayEndTime = this.myForm.value['Thursday-endTime-0'] ? this.myForm.value['Thursday-endTime-0'] : '23:59:00.000Z';
      }
      if (this.myForm.value.Friday == true) {
          fridayStartTime = this.myForm.value['Friday-startTime-0'] ? this.myForm.value['Friday-startTime-0'] : '00:00:00.000Z';
          fridayEndTime = this.myForm.value['Friday-endTime-0'] ? this.myForm.value['Friday-endTime-0'] : '23:59:00.000Z';
      }
      if (this.myForm.value.Saturday == true) {
          saturdayStartTime = this.myForm.value['Saturday-startTime-0'] ? this.myForm.value['Saturday-startTime-0'] : '00:00:00.000Z';
          saturdayEndTime = this.myForm.value['Saturday-endTime-0'] ? this.myForm.value['Saturday-endTime-0'] : '23:59:00.000Z';
      }
      if (this.myForm.value.Sunday == true) {
          sundayStartTime = this.myForm.value['Sunday-startTime-0'] ? this.myForm.value['Sunday-startTime-0'] : '00:00:00.000Z';
          sundayEndTime = this.myForm.value['Sunday-endTime-0'] ? this.myForm.value['Sunday-endTime-0'] : '23:59:00.000Z';
      }



      const payload: NotificationPreferencesRequest = {
          employeeId: this.employeeId,
          channel: channel,
          monday: this.myForm.value.Monday,
          mondayStartTime: mondayStartTime,
          mondayEndTime: mondayEndTime,
          tuesday: this.myForm.value.Tuesday,
          tuesdayStartTime: tuesdayStartTime,
          tuesdayEndTime: tuesdayEndTime,
          wednesday: this.myForm.value.Wednesday,
          wednesdayStartTime: wednesdayStartTime,
          wednesdayEndTime: wednesdayEndTime,
          thursday: this.myForm.value.Thursday,
          thursdayStartTime: thursdayStartTime,
          thursdayEndTime: thursdayEndTime,
          friday: this.myForm.value.Friday,
          fridayStartTime: fridayStartTime,
          fridayEndTime: fridayEndTime,
          saturday: this.myForm.value.Saturday,
          saturdayStartTime: saturdayStartTime,
          saturdayEndTime: saturdayEndTime,
          sunday: this.myForm.value.Sunday,
          sundayStartTime: sundayStartTime,
          sundayEndTime: sundayEndTime,
      }

      return payload;
  }

  protected readonly event = event;
}
